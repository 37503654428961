/* eslint-disable */ /**
 *
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 *
 * yarn graphql-codegen
 *
 * for this file to be re-created
 */

import * as Types from '../../generated/types.generated';

import {
  PageInfoFieldsFragmentDoc,
  PostMeiliPageInfoFieldsFragmentDoc
} from '../../graphql/fragments/page-info-fields.fragment.generated';
import { api } from '@store/baseApi';
export type PostQueryVariables = Types.Exact<{
  id: Types.Scalars['String']['input'];
}>;

export type PostQuery = {
  __typename?: 'Query';
  post: {
    __typename?: 'Post';
    id: string;
    content: string;
    accountId: number;
    pageId?: string | null;
    tokenId?: string | null;
    repostCount: number;
    totalComments: number;
    commentableId?: string | null;
    createdAt: any;
    updatedAt: any;
    followPostOwner?: boolean | null;
    followedPage?: boolean | null;
    followedToken?: boolean | null;
    bookmarkableId?: string | null;
    isBookmarked?: boolean | null;
    originalLanguage?: string | null;
    danaViewScore?: number | null;
    burnedByOthers?: boolean | null;
    account: {
      __typename?: 'Account';
      address: string;
      hash160?: string | null;
      publicKey?: string | null;
      id: number;
      name: string;
      avatar?: string | null;
      createCommentFee?: string | null;
      telegramUsername?: string | null;
    };
    page?: {
      __typename?: 'Page';
      avatar?: string | null;
      name: string;
      id: string;
      createPostFee: string;
      createCommentFee: string;
      pageAccount: { __typename?: 'Account'; id: number; name: string; address: string; hash160?: string | null };
    } | null;
    token?: { __typename?: 'Token'; id: string; name: string; tokenId: string } | null;
    reposts?: Array<{
      __typename?: 'Repost';
      accountId?: number | null;
      account?: { __typename?: 'Account'; id: number; name: string; address: string } | null;
    }> | null;
    dana?: {
      __typename?: 'PostDana';
      danaBurnUp: number;
      danaBurnDown: number;
      danaBurnScore: number;
      danaReceivedUp: number;
      danaReceivedDown: number;
      danaReceivedScore: number;
      version: number;
    } | null;
    boostScore?: { __typename?: 'PostBoost'; boostScore: number } | null;
    translations?: Array<{
      __typename?: 'PostTranslation';
      id: string;
      translateContent?: string | null;
      translateLanguage?: string | null;
    }> | null;
    imageUploadable?: {
      __typename?: 'ImageUploadable';
      id: string;
      uploads: Array<{
        __typename?: 'Upload';
        id: string;
        sha: string;
        bucket?: string | null;
        width?: number | null;
        height?: number | null;
        cfImageId?: string | null;
        cfImageFilename?: string | null;
      }>;
    } | null;
    poll?: {
      __typename?: 'Poll';
      postId: string;
      question: string;
      startDate: any;
      endDate: any;
      canAddOption: boolean;
      singleSelect: boolean;
      defaultOptions?: Array<string> | null;
      totalVote?: number | null;
      options: Array<{
        __typename?: 'PollOption';
        id: string;
        option: string;
        pollId: string;
        danaScoreOption?: number | null;
        pollAnswerOnAccount?: Array<{
          __typename?: 'PollAnswerOnAccount';
          pollDanaScore: number;
          accountId: number;
        }> | null;
      }>;
    } | null;
    postOffer?: {
      __typename?: 'Offer';
      postId: string;
      publicKey: string;
      message: string;
      noteOffer?: string | null;
      price: string;
      coinPayment?: string | null;
      marginPercentage: number;
      localCurrency?: string | null;
      orderLimitMin: number;
      orderLimitMax: number;
      status: Types.OfferStatus;
      locationId?: string | null;
      countryId?: number | null;
      paymentMethods: Array<{
        __typename?: 'OfferPaymentMethod';
        paymentMethod: { __typename?: 'PaymentMethod'; id: number; name: string };
      }>;
      location?: {
        __typename?: 'Location';
        id: string;
        iso2?: string | null;
        country?: string | null;
        adminNameAscii?: string | null;
        adminCode?: string | null;
        cityAscii?: string | null;
      } | null;
      country?: { __typename?: 'Country'; name?: string | null } | null;
    } | null;
  };
};

export type PostsByPageIdQueryVariables = Types.Exact<{
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
  before?: Types.InputMaybe<Types.Scalars['String']['input']>;
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  last?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  orderBy?: Types.InputMaybe<Array<Types.PostOrder> | Types.PostOrder>;
  id?: Types.InputMaybe<Types.Scalars['String']['input']>;
  accountId?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  skip?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  minBurnFilter?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;

export type PostsByPageIdQuery = {
  __typename?: 'Query';
  allPostsByPageId: {
    __typename?: 'PostConnection';
    totalCount?: number | null;
    edges?: Array<{
      __typename?: 'PostEdge';
      cursor: string;
      node: {
        __typename?: 'Post';
        id: string;
        content: string;
        accountId: number;
        pageId?: string | null;
        tokenId?: string | null;
        repostCount: number;
        totalComments: number;
        commentableId?: string | null;
        createdAt: any;
        updatedAt: any;
        followPostOwner?: boolean | null;
        followedPage?: boolean | null;
        followedToken?: boolean | null;
        bookmarkableId?: string | null;
        isBookmarked?: boolean | null;
        originalLanguage?: string | null;
        danaViewScore?: number | null;
        burnedByOthers?: boolean | null;
        account: {
          __typename?: 'Account';
          address: string;
          hash160?: string | null;
          publicKey?: string | null;
          id: number;
          name: string;
          avatar?: string | null;
          createCommentFee?: string | null;
          telegramUsername?: string | null;
        };
        page?: {
          __typename?: 'Page';
          avatar?: string | null;
          name: string;
          id: string;
          createPostFee: string;
          createCommentFee: string;
          pageAccount: { __typename?: 'Account'; id: number; name: string; address: string; hash160?: string | null };
        } | null;
        token?: { __typename?: 'Token'; id: string; name: string; tokenId: string } | null;
        reposts?: Array<{
          __typename?: 'Repost';
          accountId?: number | null;
          account?: { __typename?: 'Account'; id: number; name: string; address: string } | null;
        }> | null;
        dana?: {
          __typename?: 'PostDana';
          danaBurnUp: number;
          danaBurnDown: number;
          danaBurnScore: number;
          danaReceivedUp: number;
          danaReceivedDown: number;
          danaReceivedScore: number;
          version: number;
        } | null;
        boostScore?: { __typename?: 'PostBoost'; boostScore: number } | null;
        translations?: Array<{
          __typename?: 'PostTranslation';
          id: string;
          translateContent?: string | null;
          translateLanguage?: string | null;
        }> | null;
        imageUploadable?: {
          __typename?: 'ImageUploadable';
          id: string;
          uploads: Array<{
            __typename?: 'Upload';
            id: string;
            sha: string;
            bucket?: string | null;
            width?: number | null;
            height?: number | null;
            cfImageId?: string | null;
            cfImageFilename?: string | null;
          }>;
        } | null;
        poll?: {
          __typename?: 'Poll';
          postId: string;
          question: string;
          startDate: any;
          endDate: any;
          canAddOption: boolean;
          singleSelect: boolean;
          defaultOptions?: Array<string> | null;
          totalVote?: number | null;
          options: Array<{
            __typename?: 'PollOption';
            id: string;
            option: string;
            pollId: string;
            danaScoreOption?: number | null;
            pollAnswerOnAccount?: Array<{
              __typename?: 'PollAnswerOnAccount';
              pollDanaScore: number;
              accountId: number;
            }> | null;
          }>;
        } | null;
        postOffer?: {
          __typename?: 'Offer';
          postId: string;
          publicKey: string;
          message: string;
          noteOffer?: string | null;
          price: string;
          coinPayment?: string | null;
          marginPercentage: number;
          localCurrency?: string | null;
          orderLimitMin: number;
          orderLimitMax: number;
          status: Types.OfferStatus;
          locationId?: string | null;
          countryId?: number | null;
          paymentMethods: Array<{
            __typename?: 'OfferPaymentMethod';
            paymentMethod: { __typename?: 'PaymentMethod'; id: number; name: string };
          }>;
          location?: {
            __typename?: 'Location';
            id: string;
            iso2?: string | null;
            country?: string | null;
            adminNameAscii?: string | null;
            adminCode?: string | null;
            cityAscii?: string | null;
          } | null;
          country?: { __typename?: 'Country'; name?: string | null } | null;
        } | null;
      };
    }> | null;
    pageInfo: {
      __typename?: 'PageInfo';
      endCursor?: string | null;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string | null;
    };
  };
};

export type PostsByUserIdQueryVariables = Types.Exact<{
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
  before?: Types.InputMaybe<Types.Scalars['String']['input']>;
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  last?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  orderBy?: Types.InputMaybe<Array<Types.PostOrder> | Types.PostOrder>;
  id?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  skip?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  minBurnFilter?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;

export type PostsByUserIdQuery = {
  __typename?: 'Query';
  allPostsByUserId: {
    __typename?: 'PostConnection';
    totalCount?: number | null;
    edges?: Array<{
      __typename?: 'PostEdge';
      cursor: string;
      node: {
        __typename?: 'Post';
        id: string;
        content: string;
        accountId: number;
        pageId?: string | null;
        tokenId?: string | null;
        repostCount: number;
        totalComments: number;
        commentableId?: string | null;
        createdAt: any;
        updatedAt: any;
        followPostOwner?: boolean | null;
        followedPage?: boolean | null;
        followedToken?: boolean | null;
        bookmarkableId?: string | null;
        isBookmarked?: boolean | null;
        originalLanguage?: string | null;
        danaViewScore?: number | null;
        burnedByOthers?: boolean | null;
        account: {
          __typename?: 'Account';
          address: string;
          hash160?: string | null;
          publicKey?: string | null;
          id: number;
          name: string;
          avatar?: string | null;
          createCommentFee?: string | null;
          telegramUsername?: string | null;
        };
        page?: {
          __typename?: 'Page';
          avatar?: string | null;
          name: string;
          id: string;
          createPostFee: string;
          createCommentFee: string;
          pageAccount: { __typename?: 'Account'; id: number; name: string; address: string; hash160?: string | null };
        } | null;
        token?: { __typename?: 'Token'; id: string; name: string; tokenId: string } | null;
        reposts?: Array<{
          __typename?: 'Repost';
          accountId?: number | null;
          account?: { __typename?: 'Account'; id: number; name: string; address: string } | null;
        }> | null;
        dana?: {
          __typename?: 'PostDana';
          danaBurnUp: number;
          danaBurnDown: number;
          danaBurnScore: number;
          danaReceivedUp: number;
          danaReceivedDown: number;
          danaReceivedScore: number;
          version: number;
        } | null;
        boostScore?: { __typename?: 'PostBoost'; boostScore: number } | null;
        translations?: Array<{
          __typename?: 'PostTranslation';
          id: string;
          translateContent?: string | null;
          translateLanguage?: string | null;
        }> | null;
        imageUploadable?: {
          __typename?: 'ImageUploadable';
          id: string;
          uploads: Array<{
            __typename?: 'Upload';
            id: string;
            sha: string;
            bucket?: string | null;
            width?: number | null;
            height?: number | null;
            cfImageId?: string | null;
            cfImageFilename?: string | null;
          }>;
        } | null;
        poll?: {
          __typename?: 'Poll';
          postId: string;
          question: string;
          startDate: any;
          endDate: any;
          canAddOption: boolean;
          singleSelect: boolean;
          defaultOptions?: Array<string> | null;
          totalVote?: number | null;
          options: Array<{
            __typename?: 'PollOption';
            id: string;
            option: string;
            pollId: string;
            danaScoreOption?: number | null;
            pollAnswerOnAccount?: Array<{
              __typename?: 'PollAnswerOnAccount';
              pollDanaScore: number;
              accountId: number;
            }> | null;
          }>;
        } | null;
        postOffer?: {
          __typename?: 'Offer';
          postId: string;
          publicKey: string;
          message: string;
          noteOffer?: string | null;
          price: string;
          coinPayment?: string | null;
          marginPercentage: number;
          localCurrency?: string | null;
          orderLimitMin: number;
          orderLimitMax: number;
          status: Types.OfferStatus;
          locationId?: string | null;
          countryId?: number | null;
          paymentMethods: Array<{
            __typename?: 'OfferPaymentMethod';
            paymentMethod: { __typename?: 'PaymentMethod'; id: number; name: string };
          }>;
          location?: {
            __typename?: 'Location';
            id: string;
            iso2?: string | null;
            country?: string | null;
            adminNameAscii?: string | null;
            adminCode?: string | null;
            cityAscii?: string | null;
          } | null;
          country?: { __typename?: 'Country'; name?: string | null } | null;
        } | null;
      };
    }> | null;
    pageInfo: {
      __typename?: 'PageInfo';
      endCursor?: string | null;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string | null;
    };
  };
};

export type PostsByHashtagIdQueryVariables = Types.Exact<{
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
  before?: Types.InputMaybe<Types.Scalars['String']['input']>;
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  last?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  orderBy?: Types.InputMaybe<Types.PostOrder>;
  id?: Types.InputMaybe<Types.Scalars['String']['input']>;
  skip?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  minBurnFilter?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;

export type PostsByHashtagIdQuery = {
  __typename?: 'Query';
  allPostsByHashtagId: {
    __typename?: 'PostConnection';
    totalCount?: number | null;
    edges?: Array<{
      __typename?: 'PostEdge';
      cursor: string;
      node: {
        __typename?: 'Post';
        id: string;
        content: string;
        accountId: number;
        pageId?: string | null;
        tokenId?: string | null;
        repostCount: number;
        totalComments: number;
        commentableId?: string | null;
        createdAt: any;
        updatedAt: any;
        followPostOwner?: boolean | null;
        followedPage?: boolean | null;
        followedToken?: boolean | null;
        bookmarkableId?: string | null;
        isBookmarked?: boolean | null;
        originalLanguage?: string | null;
        danaViewScore?: number | null;
        burnedByOthers?: boolean | null;
        account: {
          __typename?: 'Account';
          address: string;
          hash160?: string | null;
          publicKey?: string | null;
          id: number;
          name: string;
          avatar?: string | null;
          createCommentFee?: string | null;
          telegramUsername?: string | null;
        };
        page?: {
          __typename?: 'Page';
          avatar?: string | null;
          name: string;
          id: string;
          createPostFee: string;
          createCommentFee: string;
          pageAccount: { __typename?: 'Account'; id: number; name: string; address: string; hash160?: string | null };
        } | null;
        token?: { __typename?: 'Token'; id: string; name: string; tokenId: string } | null;
        reposts?: Array<{
          __typename?: 'Repost';
          accountId?: number | null;
          account?: { __typename?: 'Account'; id: number; name: string; address: string } | null;
        }> | null;
        dana?: {
          __typename?: 'PostDana';
          danaBurnUp: number;
          danaBurnDown: number;
          danaBurnScore: number;
          danaReceivedUp: number;
          danaReceivedDown: number;
          danaReceivedScore: number;
          version: number;
        } | null;
        boostScore?: { __typename?: 'PostBoost'; boostScore: number } | null;
        translations?: Array<{
          __typename?: 'PostTranslation';
          id: string;
          translateContent?: string | null;
          translateLanguage?: string | null;
        }> | null;
        imageUploadable?: {
          __typename?: 'ImageUploadable';
          id: string;
          uploads: Array<{
            __typename?: 'Upload';
            id: string;
            sha: string;
            bucket?: string | null;
            width?: number | null;
            height?: number | null;
            cfImageId?: string | null;
            cfImageFilename?: string | null;
          }>;
        } | null;
        poll?: {
          __typename?: 'Poll';
          postId: string;
          question: string;
          startDate: any;
          endDate: any;
          canAddOption: boolean;
          singleSelect: boolean;
          defaultOptions?: Array<string> | null;
          totalVote?: number | null;
          options: Array<{
            __typename?: 'PollOption';
            id: string;
            option: string;
            pollId: string;
            danaScoreOption?: number | null;
            pollAnswerOnAccount?: Array<{
              __typename?: 'PollAnswerOnAccount';
              pollDanaScore: number;
              accountId: number;
            }> | null;
          }>;
        } | null;
        postOffer?: {
          __typename?: 'Offer';
          postId: string;
          publicKey: string;
          message: string;
          noteOffer?: string | null;
          price: string;
          coinPayment?: string | null;
          marginPercentage: number;
          localCurrency?: string | null;
          orderLimitMin: number;
          orderLimitMax: number;
          status: Types.OfferStatus;
          locationId?: string | null;
          countryId?: number | null;
          paymentMethods: Array<{
            __typename?: 'OfferPaymentMethod';
            paymentMethod: { __typename?: 'PaymentMethod'; id: number; name: string };
          }>;
          location?: {
            __typename?: 'Location';
            id: string;
            iso2?: string | null;
            country?: string | null;
            adminNameAscii?: string | null;
            adminCode?: string | null;
            cityAscii?: string | null;
          } | null;
          country?: { __typename?: 'Country'; name?: string | null } | null;
        } | null;
      };
    }> | null;
    pageInfo: {
      __typename?: 'PageInfo';
      endCursor?: string | null;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string | null;
    };
  };
};

export type PostsByTokenIdQueryVariables = Types.Exact<{
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
  before?: Types.InputMaybe<Types.Scalars['String']['input']>;
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  last?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  orderBy?: Types.InputMaybe<Types.PostOrder>;
  id?: Types.InputMaybe<Types.Scalars['String']['input']>;
  skip?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  minBurnFilter?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;

export type PostsByTokenIdQuery = {
  __typename?: 'Query';
  allPostsByTokenId: {
    __typename?: 'PostConnection';
    totalCount?: number | null;
    edges?: Array<{
      __typename?: 'PostEdge';
      cursor: string;
      node: {
        __typename?: 'Post';
        id: string;
        content: string;
        accountId: number;
        pageId?: string | null;
        tokenId?: string | null;
        repostCount: number;
        totalComments: number;
        commentableId?: string | null;
        createdAt: any;
        updatedAt: any;
        followPostOwner?: boolean | null;
        followedPage?: boolean | null;
        followedToken?: boolean | null;
        bookmarkableId?: string | null;
        isBookmarked?: boolean | null;
        originalLanguage?: string | null;
        danaViewScore?: number | null;
        burnedByOthers?: boolean | null;
        account: {
          __typename?: 'Account';
          address: string;
          hash160?: string | null;
          publicKey?: string | null;
          id: number;
          name: string;
          avatar?: string | null;
          createCommentFee?: string | null;
          telegramUsername?: string | null;
        };
        page?: {
          __typename?: 'Page';
          avatar?: string | null;
          name: string;
          id: string;
          createPostFee: string;
          createCommentFee: string;
          pageAccount: { __typename?: 'Account'; id: number; name: string; address: string; hash160?: string | null };
        } | null;
        token?: { __typename?: 'Token'; id: string; name: string; tokenId: string } | null;
        reposts?: Array<{
          __typename?: 'Repost';
          accountId?: number | null;
          account?: { __typename?: 'Account'; id: number; name: string; address: string } | null;
        }> | null;
        dana?: {
          __typename?: 'PostDana';
          danaBurnUp: number;
          danaBurnDown: number;
          danaBurnScore: number;
          danaReceivedUp: number;
          danaReceivedDown: number;
          danaReceivedScore: number;
          version: number;
        } | null;
        boostScore?: { __typename?: 'PostBoost'; boostScore: number } | null;
        translations?: Array<{
          __typename?: 'PostTranslation';
          id: string;
          translateContent?: string | null;
          translateLanguage?: string | null;
        }> | null;
        imageUploadable?: {
          __typename?: 'ImageUploadable';
          id: string;
          uploads: Array<{
            __typename?: 'Upload';
            id: string;
            sha: string;
            bucket?: string | null;
            width?: number | null;
            height?: number | null;
            cfImageId?: string | null;
            cfImageFilename?: string | null;
          }>;
        } | null;
        poll?: {
          __typename?: 'Poll';
          postId: string;
          question: string;
          startDate: any;
          endDate: any;
          canAddOption: boolean;
          singleSelect: boolean;
          defaultOptions?: Array<string> | null;
          totalVote?: number | null;
          options: Array<{
            __typename?: 'PollOption';
            id: string;
            option: string;
            pollId: string;
            danaScoreOption?: number | null;
            pollAnswerOnAccount?: Array<{
              __typename?: 'PollAnswerOnAccount';
              pollDanaScore: number;
              accountId: number;
            }> | null;
          }>;
        } | null;
        postOffer?: {
          __typename?: 'Offer';
          postId: string;
          publicKey: string;
          message: string;
          noteOffer?: string | null;
          price: string;
          coinPayment?: string | null;
          marginPercentage: number;
          localCurrency?: string | null;
          orderLimitMin: number;
          orderLimitMax: number;
          status: Types.OfferStatus;
          locationId?: string | null;
          countryId?: number | null;
          paymentMethods: Array<{
            __typename?: 'OfferPaymentMethod';
            paymentMethod: { __typename?: 'PaymentMethod'; id: number; name: string };
          }>;
          location?: {
            __typename?: 'Location';
            id: string;
            iso2?: string | null;
            country?: string | null;
            adminNameAscii?: string | null;
            adminCode?: string | null;
            cityAscii?: string | null;
          } | null;
          country?: { __typename?: 'Country'; name?: string | null } | null;
        } | null;
      };
    }> | null;
    pageInfo: {
      __typename?: 'PageInfo';
      endCursor?: string | null;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string | null;
    };
  };
};

export type PostsBySearchQueryVariables = Types.Exact<{
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
  before?: Types.InputMaybe<Types.Scalars['String']['input']>;
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  last?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  query?: Types.InputMaybe<Types.Scalars['String']['input']>;
  minBurnFilter?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;

export type PostsBySearchQuery = {
  __typename?: 'Query';
  allPostsBySearch: {
    __typename?: 'PostConnection';
    edges?: Array<{
      __typename?: 'PostEdge';
      cursor: string;
      node: {
        __typename?: 'Post';
        id: string;
        content: string;
        totalComments: number;
        createdAt: any;
        updatedAt: any;
        followPostOwner?: boolean | null;
        followedPage?: boolean | null;
        followedToken?: boolean | null;
        bookmarkableId?: string | null;
        isBookmarked?: boolean | null;
        originalLanguage?: string | null;
        danaViewScore?: number | null;
        burnedByOthers?: boolean | null;
        account: {
          __typename?: 'Account';
          address: string;
          hash160?: string | null;
          id: number;
          name: string;
          avatar?: string | null;
          createCommentFee?: string | null;
        };
        page?: {
          __typename?: 'Page';
          avatar?: string | null;
          name: string;
          id: string;
          createPostFee: string;
          createCommentFee: string;
          pageAccount: { __typename?: 'Account'; id: number; name: string; address: string; hash160?: string | null };
        } | null;
        token?: { __typename?: 'Token'; id: string; name: string; tokenId: string } | null;
        reposts?: Array<{
          __typename?: 'Repost';
          accountId?: number | null;
          account?: { __typename?: 'Account'; id: number; name: string; address: string } | null;
        }> | null;
        dana?: {
          __typename?: 'PostDana';
          danaBurnUp: number;
          danaBurnDown: number;
          danaBurnScore: number;
          danaReceivedUp: number;
          danaReceivedDown: number;
          danaReceivedScore: number;
          version: number;
        } | null;
        boostScore?: { __typename?: 'PostBoost'; boostScore: number } | null;
        translations?: Array<{
          __typename?: 'PostTranslation';
          id: string;
          translateContent?: string | null;
          translateLanguage?: string | null;
        }> | null;
        imageUploadable?: {
          __typename?: 'ImageUploadable';
          id: string;
          uploads: Array<{
            __typename?: 'Upload';
            id: string;
            sha: string;
            bucket?: string | null;
            width?: number | null;
            height?: number | null;
            cfImageId?: string | null;
            cfImageFilename?: string | null;
          }>;
        } | null;
        poll?: {
          __typename?: 'Poll';
          postId: string;
          question: string;
          startDate: any;
          endDate: any;
          canAddOption: boolean;
          singleSelect: boolean;
          defaultOptions?: Array<string> | null;
          totalVote?: number | null;
          options: Array<{
            __typename?: 'PollOption';
            id: string;
            option: string;
            pollId: string;
            danaScoreOption?: number | null;
            pollAnswerOnAccount?: Array<{
              __typename?: 'PollAnswerOnAccount';
              pollDanaScore: number;
              accountId: number;
            }> | null;
          }>;
        } | null;
        offer?: {
          __typename?: 'Offer';
          postId: string;
          publicKey: string;
          message: string;
          noteOffer?: string | null;
          price: string;
          coinPayment?: string | null;
          marginPercentage: number;
          localCurrency?: string | null;
          orderLimitMin: number;
          orderLimitMax: number;
          status: Types.OfferStatus;
          locationId?: string | null;
          countryId?: number | null;
          paymentMethods: Array<{
            __typename?: 'OfferPaymentMethod';
            paymentMethod: { __typename?: 'PaymentMethod'; id: number; name: string };
          }>;
          location?: {
            __typename?: 'Location';
            id: string;
            iso2?: string | null;
            country?: string | null;
            adminNameAscii?: string | null;
            adminCode?: string | null;
            cityAscii?: string | null;
          } | null;
          country?: { __typename?: 'Country'; name?: string | null } | null;
        } | null;
      };
    }> | null;
    pageInfo: {
      __typename?: 'PageInfo';
      endCursor?: string | null;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string | null;
    };
  };
};

export type PostsBySearchWithHashtagQueryVariables = Types.Exact<{
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
  before?: Types.InputMaybe<Types.Scalars['String']['input']>;
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  last?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  query?: Types.InputMaybe<Types.Scalars['String']['input']>;
  orderBy?: Types.InputMaybe<Types.PostOrder>;
  minBurnFilter?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  hashtags?: Types.InputMaybe<Array<Types.Scalars['String']['input']> | Types.Scalars['String']['input']>;
}>;

export type PostsBySearchWithHashtagQuery = {
  __typename?: 'Query';
  allPostsBySearchWithHashtag: {
    __typename?: 'PostConnection';
    edges?: Array<{
      __typename?: 'PostEdge';
      cursor: string;
      node: {
        __typename?: 'Post';
        id: string;
        content: string;
        totalComments: number;
        createdAt: any;
        updatedAt: any;
        followPostOwner?: boolean | null;
        followedPage?: boolean | null;
        followedToken?: boolean | null;
        bookmarkableId?: string | null;
        isBookmarked?: boolean | null;
        originalLanguage?: string | null;
        danaViewScore?: number | null;
        burnedByOthers?: boolean | null;
        account: {
          __typename?: 'Account';
          address: string;
          hash160?: string | null;
          id: number;
          name: string;
          avatar?: string | null;
          createCommentFee?: string | null;
        };
        page?: {
          __typename?: 'Page';
          avatar?: string | null;
          name: string;
          id: string;
          createPostFee: string;
          createCommentFee: string;
          pageAccount: { __typename?: 'Account'; id: number; name: string; address: string; hash160?: string | null };
        } | null;
        token?: { __typename?: 'Token'; id: string; name: string; tokenId: string } | null;
        reposts?: Array<{
          __typename?: 'Repost';
          accountId?: number | null;
          account?: { __typename?: 'Account'; id: number; name: string; address: string } | null;
        }> | null;
        dana?: {
          __typename?: 'PostDana';
          danaBurnUp: number;
          danaBurnDown: number;
          danaBurnScore: number;
          danaReceivedUp: number;
          danaReceivedDown: number;
          danaReceivedScore: number;
          version: number;
        } | null;
        boostScore?: { __typename?: 'PostBoost'; boostScore: number } | null;
        translations?: Array<{
          __typename?: 'PostTranslation';
          id: string;
          translateContent?: string | null;
          translateLanguage?: string | null;
        }> | null;
        imageUploadable?: {
          __typename?: 'ImageUploadable';
          id: string;
          uploads: Array<{
            __typename?: 'Upload';
            id: string;
            sha: string;
            bucket?: string | null;
            width?: number | null;
            height?: number | null;
            cfImageId?: string | null;
            cfImageFilename?: string | null;
          }>;
        } | null;
        poll?: {
          __typename?: 'Poll';
          postId: string;
          question: string;
          startDate: any;
          endDate: any;
          canAddOption: boolean;
          singleSelect: boolean;
          defaultOptions?: Array<string> | null;
          totalVote?: number | null;
          options: Array<{
            __typename?: 'PollOption';
            id: string;
            option: string;
            pollId: string;
            danaScoreOption?: number | null;
            pollAnswerOnAccount?: Array<{
              __typename?: 'PollAnswerOnAccount';
              pollDanaScore: number;
              accountId: number;
            }> | null;
          }>;
        } | null;
        offer?: {
          __typename?: 'Offer';
          postId: string;
          publicKey: string;
          message: string;
          noteOffer?: string | null;
          price: string;
          coinPayment?: string | null;
          marginPercentage: number;
          localCurrency?: string | null;
          orderLimitMin: number;
          orderLimitMax: number;
          status: Types.OfferStatus;
          locationId?: string | null;
          countryId?: number | null;
          paymentMethods: Array<{
            __typename?: 'OfferPaymentMethod';
            paymentMethod: { __typename?: 'PaymentMethod'; id: number; name: string };
          }>;
          location?: {
            __typename?: 'Location';
            id: string;
            iso2?: string | null;
            country?: string | null;
            adminNameAscii?: string | null;
            adminCode?: string | null;
            cityAscii?: string | null;
          } | null;
          country?: { __typename?: 'Country'; name?: string | null } | null;
        } | null;
      };
    }> | null;
    pageInfo: {
      __typename?: 'PageInfo';
      endCursor?: string | null;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string | null;
    };
  };
};

export type PostsBySearchWithHashtagAtPageQueryVariables = Types.Exact<{
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
  before?: Types.InputMaybe<Types.Scalars['String']['input']>;
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  last?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  query?: Types.InputMaybe<Types.Scalars['String']['input']>;
  orderBy?: Types.InputMaybe<Types.PostOrder>;
  minBurnFilter?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  hashtags?: Types.InputMaybe<Array<Types.Scalars['String']['input']> | Types.Scalars['String']['input']>;
  pageId?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;

export type PostsBySearchWithHashtagAtPageQuery = {
  __typename?: 'Query';
  allPostsBySearchWithHashtagAtPage: {
    __typename?: 'PostConnection';
    edges?: Array<{
      __typename?: 'PostEdge';
      cursor: string;
      node: {
        __typename?: 'Post';
        id: string;
        content: string;
        totalComments: number;
        createdAt: any;
        updatedAt: any;
        followPostOwner?: boolean | null;
        followedPage?: boolean | null;
        followedToken?: boolean | null;
        bookmarkableId?: string | null;
        isBookmarked?: boolean | null;
        originalLanguage?: string | null;
        danaViewScore?: number | null;
        burnedByOthers?: boolean | null;
        account: {
          __typename?: 'Account';
          address: string;
          hash160?: string | null;
          id: number;
          name: string;
          avatar?: string | null;
          createCommentFee?: string | null;
        };
        page?: {
          __typename?: 'Page';
          avatar?: string | null;
          name: string;
          id: string;
          createPostFee: string;
          createCommentFee: string;
          pageAccount: { __typename?: 'Account'; id: number; name: string; address: string; hash160?: string | null };
        } | null;
        token?: { __typename?: 'Token'; id: string; name: string; tokenId: string } | null;
        reposts?: Array<{
          __typename?: 'Repost';
          accountId?: number | null;
          account?: { __typename?: 'Account'; id: number; name: string; address: string } | null;
        }> | null;
        dana?: {
          __typename?: 'PostDana';
          danaBurnUp: number;
          danaBurnDown: number;
          danaBurnScore: number;
          danaReceivedUp: number;
          danaReceivedDown: number;
          danaReceivedScore: number;
          version: number;
        } | null;
        boostScore?: { __typename?: 'PostBoost'; boostScore: number } | null;
        translations?: Array<{
          __typename?: 'PostTranslation';
          id: string;
          translateContent?: string | null;
          translateLanguage?: string | null;
        }> | null;
        imageUploadable?: {
          __typename?: 'ImageUploadable';
          id: string;
          uploads: Array<{
            __typename?: 'Upload';
            id: string;
            sha: string;
            bucket?: string | null;
            width?: number | null;
            height?: number | null;
            cfImageId?: string | null;
            cfImageFilename?: string | null;
          }>;
        } | null;
        poll?: {
          __typename?: 'Poll';
          postId: string;
          question: string;
          startDate: any;
          endDate: any;
          canAddOption: boolean;
          singleSelect: boolean;
          defaultOptions?: Array<string> | null;
          totalVote?: number | null;
          options: Array<{
            __typename?: 'PollOption';
            id: string;
            option: string;
            pollId: string;
            danaScoreOption?: number | null;
            pollAnswerOnAccount?: Array<{
              __typename?: 'PollAnswerOnAccount';
              pollDanaScore: number;
              accountId: number;
            }> | null;
          }>;
        } | null;
        offer?: {
          __typename?: 'Offer';
          postId: string;
          publicKey: string;
          message: string;
          noteOffer?: string | null;
          price: string;
          coinPayment?: string | null;
          marginPercentage: number;
          localCurrency?: string | null;
          orderLimitMin: number;
          orderLimitMax: number;
          status: Types.OfferStatus;
          locationId?: string | null;
          countryId?: number | null;
          paymentMethods: Array<{
            __typename?: 'OfferPaymentMethod';
            paymentMethod: { __typename?: 'PaymentMethod'; id: number; name: string };
          }>;
          location?: {
            __typename?: 'Location';
            id: string;
            iso2?: string | null;
            country?: string | null;
            adminNameAscii?: string | null;
            adminCode?: string | null;
            cityAscii?: string | null;
          } | null;
          country?: { __typename?: 'Country'; name?: string | null } | null;
        } | null;
      };
    }> | null;
    pageInfo: {
      __typename?: 'PageInfo';
      endCursor?: string | null;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string | null;
    };
  };
};

export type PostsBySearchWithHashtagAtTokenQueryVariables = Types.Exact<{
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
  before?: Types.InputMaybe<Types.Scalars['String']['input']>;
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  last?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  query?: Types.InputMaybe<Types.Scalars['String']['input']>;
  orderBy?: Types.InputMaybe<Types.PostOrder>;
  minBurnFilter?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  hashtags?: Types.InputMaybe<Array<Types.Scalars['String']['input']> | Types.Scalars['String']['input']>;
  tokenId?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;

export type PostsBySearchWithHashtagAtTokenQuery = {
  __typename?: 'Query';
  allPostsBySearchWithHashtagAtToken: {
    __typename?: 'PostConnection';
    edges?: Array<{
      __typename?: 'PostEdge';
      cursor: string;
      node: {
        __typename?: 'Post';
        id: string;
        content: string;
        totalComments: number;
        createdAt: any;
        updatedAt: any;
        followPostOwner?: boolean | null;
        followedPage?: boolean | null;
        followedToken?: boolean | null;
        bookmarkableId?: string | null;
        isBookmarked?: boolean | null;
        originalLanguage?: string | null;
        danaViewScore?: number | null;
        burnedByOthers?: boolean | null;
        account: {
          __typename?: 'Account';
          address: string;
          hash160?: string | null;
          id: number;
          name: string;
          avatar?: string | null;
          createCommentFee?: string | null;
        };
        page?: {
          __typename?: 'Page';
          avatar?: string | null;
          name: string;
          id: string;
          createPostFee: string;
          createCommentFee: string;
          pageAccount: { __typename?: 'Account'; id: number; name: string; address: string; hash160?: string | null };
        } | null;
        token?: { __typename?: 'Token'; id: string; name: string; tokenId: string } | null;
        reposts?: Array<{
          __typename?: 'Repost';
          accountId?: number | null;
          account?: { __typename?: 'Account'; id: number; name: string; address: string } | null;
        }> | null;
        dana?: {
          __typename?: 'PostDana';
          danaBurnUp: number;
          danaBurnDown: number;
          danaBurnScore: number;
          danaReceivedUp: number;
          danaReceivedDown: number;
          danaReceivedScore: number;
          version: number;
        } | null;
        boostScore?: { __typename?: 'PostBoost'; boostScore: number } | null;
        translations?: Array<{
          __typename?: 'PostTranslation';
          id: string;
          translateContent?: string | null;
          translateLanguage?: string | null;
        }> | null;
        imageUploadable?: {
          __typename?: 'ImageUploadable';
          id: string;
          uploads: Array<{
            __typename?: 'Upload';
            id: string;
            sha: string;
            bucket?: string | null;
            width?: number | null;
            height?: number | null;
            cfImageId?: string | null;
            cfImageFilename?: string | null;
          }>;
        } | null;
        poll?: {
          __typename?: 'Poll';
          postId: string;
          question: string;
          startDate: any;
          endDate: any;
          canAddOption: boolean;
          singleSelect: boolean;
          defaultOptions?: Array<string> | null;
          totalVote?: number | null;
          options: Array<{
            __typename?: 'PollOption';
            id: string;
            option: string;
            pollId: string;
            danaScoreOption?: number | null;
            pollAnswerOnAccount?: Array<{
              __typename?: 'PollAnswerOnAccount';
              pollDanaScore: number;
              accountId: number;
            }> | null;
          }>;
        } | null;
        offer?: {
          __typename?: 'Offer';
          postId: string;
          publicKey: string;
          message: string;
          noteOffer?: string | null;
          price: string;
          coinPayment?: string | null;
          marginPercentage: number;
          localCurrency?: string | null;
          orderLimitMin: number;
          orderLimitMax: number;
          status: Types.OfferStatus;
          locationId?: string | null;
          countryId?: number | null;
          paymentMethods: Array<{
            __typename?: 'OfferPaymentMethod';
            paymentMethod: { __typename?: 'PaymentMethod'; id: number; name: string };
          }>;
          location?: {
            __typename?: 'Location';
            id: string;
            iso2?: string | null;
            country?: string | null;
            adminNameAscii?: string | null;
            adminCode?: string | null;
            cityAscii?: string | null;
          } | null;
          country?: { __typename?: 'Country'; name?: string | null } | null;
        } | null;
      };
    }> | null;
    pageInfo: {
      __typename?: 'PageInfo';
      endCursor?: string | null;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string | null;
    };
  };
};

export type PollFieldsFragment = {
  __typename?: 'Poll';
  postId: string;
  question: string;
  startDate: any;
  endDate: any;
  canAddOption: boolean;
  singleSelect: boolean;
  defaultOptions?: Array<string> | null;
  totalVote?: number | null;
  options: Array<{
    __typename?: 'PollOption';
    id: string;
    option: string;
    pollId: string;
    danaScoreOption?: number | null;
    pollAnswerOnAccount?: Array<{
      __typename?: 'PollAnswerOnAccount';
      pollDanaScore: number;
      accountId: number;
    }> | null;
  }>;
};

export type OfferFieldsFragment = {
  __typename?: 'Offer';
  postId: string;
  publicKey: string;
  message: string;
  noteOffer?: string | null;
  price: string;
  coinPayment?: string | null;
  marginPercentage: number;
  localCurrency?: string | null;
  orderLimitMin: number;
  orderLimitMax: number;
  status: Types.OfferStatus;
  locationId?: string | null;
  countryId?: number | null;
  paymentMethods: Array<{
    __typename?: 'OfferPaymentMethod';
    paymentMethod: { __typename?: 'PaymentMethod'; id: number; name: string };
  }>;
  location?: {
    __typename?: 'Location';
    id: string;
    iso2?: string | null;
    country?: string | null;
    adminNameAscii?: string | null;
    adminCode?: string | null;
    cityAscii?: string | null;
  } | null;
  country?: { __typename?: 'Country'; name?: string | null } | null;
};

export type PostFieldsFragment = {
  __typename?: 'Post';
  id: string;
  content: string;
  accountId: number;
  pageId?: string | null;
  tokenId?: string | null;
  repostCount: number;
  totalComments: number;
  commentableId?: string | null;
  createdAt: any;
  updatedAt: any;
  followPostOwner?: boolean | null;
  followedPage?: boolean | null;
  followedToken?: boolean | null;
  bookmarkableId?: string | null;
  isBookmarked?: boolean | null;
  originalLanguage?: string | null;
  danaViewScore?: number | null;
  burnedByOthers?: boolean | null;
  account: {
    __typename?: 'Account';
    address: string;
    hash160?: string | null;
    publicKey?: string | null;
    id: number;
    name: string;
    avatar?: string | null;
    createCommentFee?: string | null;
    telegramUsername?: string | null;
  };
  page?: {
    __typename?: 'Page';
    avatar?: string | null;
    name: string;
    id: string;
    createPostFee: string;
    createCommentFee: string;
    pageAccount: { __typename?: 'Account'; id: number; name: string; address: string; hash160?: string | null };
  } | null;
  token?: { __typename?: 'Token'; id: string; name: string; tokenId: string } | null;
  reposts?: Array<{
    __typename?: 'Repost';
    accountId?: number | null;
    account?: { __typename?: 'Account'; id: number; name: string; address: string } | null;
  }> | null;
  dana?: {
    __typename?: 'PostDana';
    danaBurnUp: number;
    danaBurnDown: number;
    danaBurnScore: number;
    danaReceivedUp: number;
    danaReceivedDown: number;
    danaReceivedScore: number;
    version: number;
  } | null;
  boostScore?: { __typename?: 'PostBoost'; boostScore: number } | null;
  translations?: Array<{
    __typename?: 'PostTranslation';
    id: string;
    translateContent?: string | null;
    translateLanguage?: string | null;
  }> | null;
  imageUploadable?: {
    __typename?: 'ImageUploadable';
    id: string;
    uploads: Array<{
      __typename?: 'Upload';
      id: string;
      sha: string;
      bucket?: string | null;
      width?: number | null;
      height?: number | null;
      cfImageId?: string | null;
      cfImageFilename?: string | null;
    }>;
  } | null;
  poll?: {
    __typename?: 'Poll';
    postId: string;
    question: string;
    startDate: any;
    endDate: any;
    canAddOption: boolean;
    singleSelect: boolean;
    defaultOptions?: Array<string> | null;
    totalVote?: number | null;
    options: Array<{
      __typename?: 'PollOption';
      id: string;
      option: string;
      pollId: string;
      danaScoreOption?: number | null;
      pollAnswerOnAccount?: Array<{
        __typename?: 'PollAnswerOnAccount';
        pollDanaScore: number;
        accountId: number;
      }> | null;
    }>;
  } | null;
  postOffer?: {
    __typename?: 'Offer';
    postId: string;
    publicKey: string;
    message: string;
    noteOffer?: string | null;
    price: string;
    coinPayment?: string | null;
    marginPercentage: number;
    localCurrency?: string | null;
    orderLimitMin: number;
    orderLimitMax: number;
    status: Types.OfferStatus;
    locationId?: string | null;
    countryId?: number | null;
    paymentMethods: Array<{
      __typename?: 'OfferPaymentMethod';
      paymentMethod: { __typename?: 'PaymentMethod'; id: number; name: string };
    }>;
    location?: {
      __typename?: 'Location';
      id: string;
      iso2?: string | null;
      country?: string | null;
      adminNameAscii?: string | null;
      adminCode?: string | null;
      cityAscii?: string | null;
    } | null;
    country?: { __typename?: 'Country'; name?: string | null } | null;
  } | null;
};

export type PostMeiliFieldsFragment = {
  __typename?: 'Post';
  id: string;
  content: string;
  totalComments: number;
  createdAt: any;
  updatedAt: any;
  followPostOwner?: boolean | null;
  followedPage?: boolean | null;
  followedToken?: boolean | null;
  bookmarkableId?: string | null;
  isBookmarked?: boolean | null;
  originalLanguage?: string | null;
  danaViewScore?: number | null;
  burnedByOthers?: boolean | null;
  account: {
    __typename?: 'Account';
    address: string;
    hash160?: string | null;
    id: number;
    name: string;
    avatar?: string | null;
    createCommentFee?: string | null;
  };
  page?: {
    __typename?: 'Page';
    avatar?: string | null;
    name: string;
    id: string;
    createPostFee: string;
    createCommentFee: string;
    pageAccount: { __typename?: 'Account'; id: number; name: string; address: string; hash160?: string | null };
  } | null;
  token?: { __typename?: 'Token'; id: string; name: string; tokenId: string } | null;
  reposts?: Array<{
    __typename?: 'Repost';
    accountId?: number | null;
    account?: { __typename?: 'Account'; id: number; name: string; address: string } | null;
  }> | null;
  dana?: {
    __typename?: 'PostDana';
    danaBurnUp: number;
    danaBurnDown: number;
    danaBurnScore: number;
    danaReceivedUp: number;
    danaReceivedDown: number;
    danaReceivedScore: number;
    version: number;
  } | null;
  boostScore?: { __typename?: 'PostBoost'; boostScore: number } | null;
  translations?: Array<{
    __typename?: 'PostTranslation';
    id: string;
    translateContent?: string | null;
    translateLanguage?: string | null;
  }> | null;
  imageUploadable?: {
    __typename?: 'ImageUploadable';
    id: string;
    uploads: Array<{
      __typename?: 'Upload';
      id: string;
      sha: string;
      bucket?: string | null;
      width?: number | null;
      height?: number | null;
      cfImageId?: string | null;
      cfImageFilename?: string | null;
    }>;
  } | null;
  poll?: {
    __typename?: 'Poll';
    postId: string;
    question: string;
    startDate: any;
    endDate: any;
    canAddOption: boolean;
    singleSelect: boolean;
    defaultOptions?: Array<string> | null;
    totalVote?: number | null;
    options: Array<{
      __typename?: 'PollOption';
      id: string;
      option: string;
      pollId: string;
      danaScoreOption?: number | null;
      pollAnswerOnAccount?: Array<{
        __typename?: 'PollAnswerOnAccount';
        pollDanaScore: number;
        accountId: number;
      }> | null;
    }>;
  } | null;
  offer?: {
    __typename?: 'Offer';
    postId: string;
    publicKey: string;
    message: string;
    noteOffer?: string | null;
    price: string;
    coinPayment?: string | null;
    marginPercentage: number;
    localCurrency?: string | null;
    orderLimitMin: number;
    orderLimitMax: number;
    status: Types.OfferStatus;
    locationId?: string | null;
    countryId?: number | null;
    paymentMethods: Array<{
      __typename?: 'OfferPaymentMethod';
      paymentMethod: { __typename?: 'PaymentMethod'; id: number; name: string };
    }>;
    location?: {
      __typename?: 'Location';
      id: string;
      iso2?: string | null;
      country?: string | null;
      adminNameAscii?: string | null;
      adminCode?: string | null;
      cityAscii?: string | null;
    } | null;
    country?: { __typename?: 'Country'; name?: string | null } | null;
  } | null;
};

export type CreatePostMutationVariables = Types.Exact<{
  input: Types.CreatePostInput;
}>;

export type CreatePostMutation = {
  __typename?: 'Mutation';
  createPost: {
    __typename?: 'Post';
    id: string;
    content: string;
    accountId: number;
    pageId?: string | null;
    tokenId?: string | null;
    repostCount: number;
    totalComments: number;
    commentableId?: string | null;
    createdAt: any;
    updatedAt: any;
    followPostOwner?: boolean | null;
    followedPage?: boolean | null;
    followedToken?: boolean | null;
    bookmarkableId?: string | null;
    isBookmarked?: boolean | null;
    originalLanguage?: string | null;
    danaViewScore?: number | null;
    burnedByOthers?: boolean | null;
    account: {
      __typename?: 'Account';
      address: string;
      hash160?: string | null;
      publicKey?: string | null;
      id: number;
      name: string;
      avatar?: string | null;
      createCommentFee?: string | null;
      telegramUsername?: string | null;
    };
    page?: {
      __typename?: 'Page';
      avatar?: string | null;
      name: string;
      id: string;
      createPostFee: string;
      createCommentFee: string;
      pageAccount: { __typename?: 'Account'; id: number; name: string; address: string; hash160?: string | null };
    } | null;
    token?: { __typename?: 'Token'; id: string; name: string; tokenId: string } | null;
    reposts?: Array<{
      __typename?: 'Repost';
      accountId?: number | null;
      account?: { __typename?: 'Account'; id: number; name: string; address: string } | null;
    }> | null;
    dana?: {
      __typename?: 'PostDana';
      danaBurnUp: number;
      danaBurnDown: number;
      danaBurnScore: number;
      danaReceivedUp: number;
      danaReceivedDown: number;
      danaReceivedScore: number;
      version: number;
    } | null;
    boostScore?: { __typename?: 'PostBoost'; boostScore: number } | null;
    translations?: Array<{
      __typename?: 'PostTranslation';
      id: string;
      translateContent?: string | null;
      translateLanguage?: string | null;
    }> | null;
    imageUploadable?: {
      __typename?: 'ImageUploadable';
      id: string;
      uploads: Array<{
        __typename?: 'Upload';
        id: string;
        sha: string;
        bucket?: string | null;
        width?: number | null;
        height?: number | null;
        cfImageId?: string | null;
        cfImageFilename?: string | null;
      }>;
    } | null;
    poll?: {
      __typename?: 'Poll';
      postId: string;
      question: string;
      startDate: any;
      endDate: any;
      canAddOption: boolean;
      singleSelect: boolean;
      defaultOptions?: Array<string> | null;
      totalVote?: number | null;
      options: Array<{
        __typename?: 'PollOption';
        id: string;
        option: string;
        pollId: string;
        danaScoreOption?: number | null;
        pollAnswerOnAccount?: Array<{
          __typename?: 'PollAnswerOnAccount';
          pollDanaScore: number;
          accountId: number;
        }> | null;
      }>;
    } | null;
    postOffer?: {
      __typename?: 'Offer';
      postId: string;
      publicKey: string;
      message: string;
      noteOffer?: string | null;
      price: string;
      coinPayment?: string | null;
      marginPercentage: number;
      localCurrency?: string | null;
      orderLimitMin: number;
      orderLimitMax: number;
      status: Types.OfferStatus;
      locationId?: string | null;
      countryId?: number | null;
      paymentMethods: Array<{
        __typename?: 'OfferPaymentMethod';
        paymentMethod: { __typename?: 'PaymentMethod'; id: number; name: string };
      }>;
      location?: {
        __typename?: 'Location';
        id: string;
        iso2?: string | null;
        country?: string | null;
        adminNameAscii?: string | null;
        adminCode?: string | null;
        cityAscii?: string | null;
      } | null;
      country?: { __typename?: 'Country'; name?: string | null } | null;
    } | null;
  };
};

export type UpdatePostMutationVariables = Types.Exact<{
  input: Types.UpdatePostInput;
}>;

export type UpdatePostMutation = {
  __typename?: 'Mutation';
  updatePost: {
    __typename?: 'Post';
    id: string;
    content: string;
    accountId: number;
    pageId?: string | null;
    tokenId?: string | null;
    repostCount: number;
    totalComments: number;
    commentableId?: string | null;
    createdAt: any;
    updatedAt: any;
    followPostOwner?: boolean | null;
    followedPage?: boolean | null;
    followedToken?: boolean | null;
    bookmarkableId?: string | null;
    isBookmarked?: boolean | null;
    originalLanguage?: string | null;
    danaViewScore?: number | null;
    burnedByOthers?: boolean | null;
    account: {
      __typename?: 'Account';
      address: string;
      hash160?: string | null;
      publicKey?: string | null;
      id: number;
      name: string;
      avatar?: string | null;
      createCommentFee?: string | null;
      telegramUsername?: string | null;
    };
    page?: {
      __typename?: 'Page';
      avatar?: string | null;
      name: string;
      id: string;
      createPostFee: string;
      createCommentFee: string;
      pageAccount: { __typename?: 'Account'; id: number; name: string; address: string; hash160?: string | null };
    } | null;
    token?: { __typename?: 'Token'; id: string; name: string; tokenId: string } | null;
    reposts?: Array<{
      __typename?: 'Repost';
      accountId?: number | null;
      account?: { __typename?: 'Account'; id: number; name: string; address: string } | null;
    }> | null;
    dana?: {
      __typename?: 'PostDana';
      danaBurnUp: number;
      danaBurnDown: number;
      danaBurnScore: number;
      danaReceivedUp: number;
      danaReceivedDown: number;
      danaReceivedScore: number;
      version: number;
    } | null;
    boostScore?: { __typename?: 'PostBoost'; boostScore: number } | null;
    translations?: Array<{
      __typename?: 'PostTranslation';
      id: string;
      translateContent?: string | null;
      translateLanguage?: string | null;
    }> | null;
    imageUploadable?: {
      __typename?: 'ImageUploadable';
      id: string;
      uploads: Array<{
        __typename?: 'Upload';
        id: string;
        sha: string;
        bucket?: string | null;
        width?: number | null;
        height?: number | null;
        cfImageId?: string | null;
        cfImageFilename?: string | null;
      }>;
    } | null;
    poll?: {
      __typename?: 'Poll';
      postId: string;
      question: string;
      startDate: any;
      endDate: any;
      canAddOption: boolean;
      singleSelect: boolean;
      defaultOptions?: Array<string> | null;
      totalVote?: number | null;
      options: Array<{
        __typename?: 'PollOption';
        id: string;
        option: string;
        pollId: string;
        danaScoreOption?: number | null;
        pollAnswerOnAccount?: Array<{
          __typename?: 'PollAnswerOnAccount';
          pollDanaScore: number;
          accountId: number;
        }> | null;
      }>;
    } | null;
    postOffer?: {
      __typename?: 'Offer';
      postId: string;
      publicKey: string;
      message: string;
      noteOffer?: string | null;
      price: string;
      coinPayment?: string | null;
      marginPercentage: number;
      localCurrency?: string | null;
      orderLimitMin: number;
      orderLimitMax: number;
      status: Types.OfferStatus;
      locationId?: string | null;
      countryId?: number | null;
      paymentMethods: Array<{
        __typename?: 'OfferPaymentMethod';
        paymentMethod: { __typename?: 'PaymentMethod'; id: number; name: string };
      }>;
      location?: {
        __typename?: 'Location';
        id: string;
        iso2?: string | null;
        country?: string | null;
        adminNameAscii?: string | null;
        adminCode?: string | null;
        cityAscii?: string | null;
      } | null;
      country?: { __typename?: 'Country'; name?: string | null } | null;
    } | null;
  };
};

export type RepostMutationVariables = Types.Exact<{
  input: Types.RepostInput;
}>;

export type RepostMutation = { __typename?: 'Mutation'; repost: boolean };

export type RemovePostMutationVariables = Types.Exact<{
  input: Types.RemovePostInput;
}>;

export type RemovePostMutation = {
  __typename?: 'Mutation';
  removePost: {
    __typename?: 'Post';
    id: string;
    content: string;
    accountId: number;
    pageId?: string | null;
    tokenId?: string | null;
    repostCount: number;
    totalComments: number;
    commentableId?: string | null;
    createdAt: any;
    updatedAt: any;
    followPostOwner?: boolean | null;
    followedPage?: boolean | null;
    followedToken?: boolean | null;
    bookmarkableId?: string | null;
    isBookmarked?: boolean | null;
    originalLanguage?: string | null;
    danaViewScore?: number | null;
    burnedByOthers?: boolean | null;
    account: {
      __typename?: 'Account';
      address: string;
      hash160?: string | null;
      publicKey?: string | null;
      id: number;
      name: string;
      avatar?: string | null;
      createCommentFee?: string | null;
      telegramUsername?: string | null;
    };
    page?: {
      __typename?: 'Page';
      avatar?: string | null;
      name: string;
      id: string;
      createPostFee: string;
      createCommentFee: string;
      pageAccount: { __typename?: 'Account'; id: number; name: string; address: string; hash160?: string | null };
    } | null;
    token?: { __typename?: 'Token'; id: string; name: string; tokenId: string } | null;
    reposts?: Array<{
      __typename?: 'Repost';
      accountId?: number | null;
      account?: { __typename?: 'Account'; id: number; name: string; address: string } | null;
    }> | null;
    dana?: {
      __typename?: 'PostDana';
      danaBurnUp: number;
      danaBurnDown: number;
      danaBurnScore: number;
      danaReceivedUp: number;
      danaReceivedDown: number;
      danaReceivedScore: number;
      version: number;
    } | null;
    boostScore?: { __typename?: 'PostBoost'; boostScore: number } | null;
    translations?: Array<{
      __typename?: 'PostTranslation';
      id: string;
      translateContent?: string | null;
      translateLanguage?: string | null;
    }> | null;
    imageUploadable?: {
      __typename?: 'ImageUploadable';
      id: string;
      uploads: Array<{
        __typename?: 'Upload';
        id: string;
        sha: string;
        bucket?: string | null;
        width?: number | null;
        height?: number | null;
        cfImageId?: string | null;
        cfImageFilename?: string | null;
      }>;
    } | null;
    poll?: {
      __typename?: 'Poll';
      postId: string;
      question: string;
      startDate: any;
      endDate: any;
      canAddOption: boolean;
      singleSelect: boolean;
      defaultOptions?: Array<string> | null;
      totalVote?: number | null;
      options: Array<{
        __typename?: 'PollOption';
        id: string;
        option: string;
        pollId: string;
        danaScoreOption?: number | null;
        pollAnswerOnAccount?: Array<{
          __typename?: 'PollAnswerOnAccount';
          pollDanaScore: number;
          accountId: number;
        }> | null;
      }>;
    } | null;
    postOffer?: {
      __typename?: 'Offer';
      postId: string;
      publicKey: string;
      message: string;
      noteOffer?: string | null;
      price: string;
      coinPayment?: string | null;
      marginPercentage: number;
      localCurrency?: string | null;
      orderLimitMin: number;
      orderLimitMax: number;
      status: Types.OfferStatus;
      locationId?: string | null;
      countryId?: number | null;
      paymentMethods: Array<{
        __typename?: 'OfferPaymentMethod';
        paymentMethod: { __typename?: 'PaymentMethod'; id: number; name: string };
      }>;
      location?: {
        __typename?: 'Location';
        id: string;
        iso2?: string | null;
        country?: string | null;
        adminNameAscii?: string | null;
        adminCode?: string | null;
        cityAscii?: string | null;
      } | null;
      country?: { __typename?: 'Country'; name?: string | null } | null;
    } | null;
  };
};

export const PollFieldsFragmentDoc = `
    fragment PollFields on Poll {
  postId
  question
  startDate
  endDate
  canAddOption
  singleSelect
  defaultOptions
  totalVote
  options {
    id
    option
    pollId
    danaScoreOption
    pollAnswerOnAccount {
      pollDanaScore
      accountId
    }
  }
}
    `;
export const OfferFieldsFragmentDoc = `
    fragment OfferFields on Offer {
  postId
  publicKey
  message
  noteOffer
  price
  coinPayment
  marginPercentage
  localCurrency
  orderLimitMin
  orderLimitMax
  paymentMethods {
    paymentMethod {
      id
      name
    }
  }
  status
  locationId
  location {
    id
    iso2
    country
    adminNameAscii
    adminCode
    cityAscii
  }
  countryId
  country {
    name
  }
}
    `;
export const PostFieldsFragmentDoc = `
    fragment PostFields on Post {
  id
  content
  account {
    address
    hash160
    publicKey
    id
    name
    avatar
    createCommentFee
    telegramUsername
  }
  accountId
  page {
    avatar
    name
    id
    createPostFee
    createCommentFee
    pageAccount {
      id
      name
      address
      hash160
    }
  }
  pageId
  token {
    id
    name
    tokenId
  }
  tokenId
  repostCount
  reposts {
    accountId
    account {
      id
      name
      address
    }
  }
  dana {
    danaBurnUp
    danaBurnDown
    danaBurnScore
    danaReceivedUp
    danaReceivedDown
    danaReceivedScore
    version
  }
  boostScore {
    boostScore
  }
  totalComments
  commentableId
  createdAt
  updatedAt
  followPostOwner
  followedPage
  followedToken
  bookmarkableId
  isBookmarked
  originalLanguage
  translations {
    id
    translateContent
    translateLanguage
  }
  imageUploadable {
    id
    uploads {
      id
      sha
      bucket
      width
      height
      cfImageId
      cfImageFilename
    }
  }
  danaViewScore
  burnedByOthers
  poll {
    ...PollFields
  }
  postOffer: offer {
    ...OfferFields
  }
}
    ${PollFieldsFragmentDoc}
${OfferFieldsFragmentDoc}`;
export const PostMeiliFieldsFragmentDoc = `
    fragment PostMeiliFields on Post {
  id
  content
  account {
    address
    hash160
    id
    name
    avatar
    createCommentFee
  }
  page {
    avatar
    name
    id
    createPostFee
    createCommentFee
    pageAccount {
      id
      name
      address
      hash160
    }
  }
  token {
    id
    name
    tokenId
  }
  reposts {
    accountId
    account {
      id
      name
      address
    }
  }
  dana {
    danaBurnUp
    danaBurnDown
    danaBurnScore
    danaReceivedUp
    danaReceivedDown
    danaReceivedScore
    version
  }
  boostScore {
    boostScore
  }
  totalComments
  createdAt
  updatedAt
  followPostOwner
  followedPage
  followedToken
  bookmarkableId
  isBookmarked
  originalLanguage
  translations {
    id
    translateContent
    translateLanguage
  }
  imageUploadable {
    id
    uploads {
      id
      sha
      bucket
      width
      height
      cfImageId
      cfImageFilename
    }
  }
  danaViewScore
  burnedByOthers
  poll {
    ...PollFields
  }
  offer {
    ...OfferFields
  }
}
    ${PollFieldsFragmentDoc}
${OfferFieldsFragmentDoc}`;
export const PostDocument = `
    query Post($id: String!) {
  post(id: $id) {
    ...PostFields
  }
}
    ${PostFieldsFragmentDoc}`;
export const PostsByPageIdDocument = `
    query PostsByPageId($after: String, $before: String, $first: Int = 20, $last: Int, $orderBy: [PostOrder!], $id: String, $accountId: Int, $skip: Int, $minBurnFilter: Int) {
  allPostsByPageId(
    after: $after
    before: $before
    first: $first
    last: $last
    orderBy: $orderBy
    id: $id
    accountId: $accountId
    skip: $skip
    minBurnFilter: $minBurnFilter
  ) {
    totalCount
    edges {
      cursor
      node {
        ...PostFields
      }
    }
    pageInfo {
      ...PageInfoFields
    }
  }
}
    ${PostFieldsFragmentDoc}
${PageInfoFieldsFragmentDoc}`;
export const PostsByUserIdDocument = `
    query PostsByUserId($after: String, $before: String, $first: Int = 20, $last: Int, $orderBy: [PostOrder!], $id: Int, $skip: Int, $minBurnFilter: Int) {
  allPostsByUserId(
    after: $after
    before: $before
    first: $first
    last: $last
    orderBy: $orderBy
    id: $id
    skip: $skip
    minBurnFilter: $minBurnFilter
  ) {
    totalCount
    edges {
      cursor
      node {
        ...PostFields
      }
    }
    pageInfo {
      ...PageInfoFields
    }
  }
}
    ${PostFieldsFragmentDoc}
${PageInfoFieldsFragmentDoc}`;
export const PostsByHashtagIdDocument = `
    query PostsByHashtagId($after: String, $before: String, $first: Int = 20, $last: Int, $orderBy: PostOrder, $id: String, $skip: Int, $minBurnFilter: Int) {
  allPostsByHashtagId(
    after: $after
    before: $before
    first: $first
    last: $last
    orderBy: $orderBy
    id: $id
    skip: $skip
    minBurnFilter: $minBurnFilter
  ) {
    totalCount
    edges {
      cursor
      node {
        ...PostFields
      }
    }
    pageInfo {
      ...PageInfoFields
    }
  }
}
    ${PostFieldsFragmentDoc}
${PageInfoFieldsFragmentDoc}`;
export const PostsByTokenIdDocument = `
    query PostsByTokenId($after: String, $before: String, $first: Int = 20, $last: Int, $orderBy: PostOrder, $id: String, $skip: Int, $minBurnFilter: Int) {
  allPostsByTokenId(
    after: $after
    before: $before
    first: $first
    last: $last
    orderBy: $orderBy
    id: $id
    skip: $skip
    minBurnFilter: $minBurnFilter
  ) {
    totalCount
    edges {
      cursor
      node {
        ...PostFields
      }
    }
    pageInfo {
      ...PageInfoFields
    }
  }
}
    ${PostFieldsFragmentDoc}
${PageInfoFieldsFragmentDoc}`;
export const PostsBySearchDocument = `
    query PostsBySearch($after: String, $before: String, $first: Int, $last: Int, $query: String, $minBurnFilter: Int) {
  allPostsBySearch(
    after: $after
    before: $before
    first: $first
    last: $last
    query: $query
    minBurnFilter: $minBurnFilter
  ) {
    edges {
      cursor
      node {
        ...PostMeiliFields
      }
    }
    pageInfo {
      ...PostMeiliPageInfoFields
    }
  }
}
    ${PostMeiliFieldsFragmentDoc}
${PostMeiliPageInfoFieldsFragmentDoc}`;
export const PostsBySearchWithHashtagDocument = `
    query PostsBySearchWithHashtag($after: String, $before: String, $first: Int, $last: Int, $query: String, $orderBy: PostOrder, $minBurnFilter: Int, $hashtags: [String!]) {
  allPostsBySearchWithHashtag(
    after: $after
    before: $before
    first: $first
    last: $last
    query: $query
    orderBy: $orderBy
    minBurnFilter: $minBurnFilter
    hashtags: $hashtags
  ) {
    edges {
      cursor
      node {
        ...PostMeiliFields
      }
    }
    pageInfo {
      ...PostMeiliPageInfoFields
    }
  }
}
    ${PostMeiliFieldsFragmentDoc}
${PostMeiliPageInfoFieldsFragmentDoc}`;
export const PostsBySearchWithHashtagAtPageDocument = `
    query PostsBySearchWithHashtagAtPage($after: String, $before: String, $first: Int, $last: Int, $query: String, $orderBy: PostOrder, $minBurnFilter: Int, $hashtags: [String!], $pageId: String) {
  allPostsBySearchWithHashtagAtPage(
    after: $after
    before: $before
    first: $first
    last: $last
    query: $query
    orderBy: $orderBy
    minBurnFilter: $minBurnFilter
    hashtags: $hashtags
    pageId: $pageId
  ) {
    edges {
      cursor
      node {
        ...PostMeiliFields
      }
    }
    pageInfo {
      ...PostMeiliPageInfoFields
    }
  }
}
    ${PostMeiliFieldsFragmentDoc}
${PostMeiliPageInfoFieldsFragmentDoc}`;
export const PostsBySearchWithHashtagAtTokenDocument = `
    query PostsBySearchWithHashtagAtToken($after: String, $before: String, $first: Int, $last: Int, $query: String, $orderBy: PostOrder, $minBurnFilter: Int, $hashtags: [String!], $tokenId: String) {
  allPostsBySearchWithHashtagAtToken(
    after: $after
    before: $before
    first: $first
    last: $last
    query: $query
    orderBy: $orderBy
    minBurnFilter: $minBurnFilter
    hashtags: $hashtags
    tokenId: $tokenId
  ) {
    edges {
      cursor
      node {
        ...PostMeiliFields
      }
    }
    pageInfo {
      ...PostMeiliPageInfoFields
    }
  }
}
    ${PostMeiliFieldsFragmentDoc}
${PostMeiliPageInfoFieldsFragmentDoc}`;
export const CreatePostDocument = `
    mutation createPost($input: CreatePostInput!) {
  createPost(data: $input) {
    ...PostFields
  }
}
    ${PostFieldsFragmentDoc}`;
export const UpdatePostDocument = `
    mutation updatePost($input: UpdatePostInput!) {
  updatePost(data: $input) {
    ...PostFields
  }
}
    ${PostFieldsFragmentDoc}`;
export const RepostDocument = `
    mutation repost($input: RepostInput!) {
  repost(data: $input)
}
    `;
export const RemovePostDocument = `
    mutation removePost($input: RemovePostInput!) {
  removePost(data: $input) {
    ...PostFields
  }
}
    ${PostFieldsFragmentDoc}`;

const injectedRtkApi = api.injectEndpoints({
  overrideExisting: true,
  endpoints: build => ({
    Post: build.query<PostQuery, PostQueryVariables>({
      query: variables => ({ document: PostDocument, variables })
    }),
    PostsByPageId: build.query<PostsByPageIdQuery, PostsByPageIdQueryVariables | void>({
      query: variables => ({ document: PostsByPageIdDocument, variables })
    }),
    PostsByUserId: build.query<PostsByUserIdQuery, PostsByUserIdQueryVariables | void>({
      query: variables => ({ document: PostsByUserIdDocument, variables })
    }),
    PostsByHashtagId: build.query<PostsByHashtagIdQuery, PostsByHashtagIdQueryVariables | void>({
      query: variables => ({ document: PostsByHashtagIdDocument, variables })
    }),
    PostsByTokenId: build.query<PostsByTokenIdQuery, PostsByTokenIdQueryVariables | void>({
      query: variables => ({ document: PostsByTokenIdDocument, variables })
    }),
    PostsBySearch: build.query<PostsBySearchQuery, PostsBySearchQueryVariables | void>({
      query: variables => ({ document: PostsBySearchDocument, variables })
    }),
    PostsBySearchWithHashtag: build.query<PostsBySearchWithHashtagQuery, PostsBySearchWithHashtagQueryVariables | void>(
      {
        query: variables => ({ document: PostsBySearchWithHashtagDocument, variables })
      }
    ),
    PostsBySearchWithHashtagAtPage: build.query<
      PostsBySearchWithHashtagAtPageQuery,
      PostsBySearchWithHashtagAtPageQueryVariables | void
    >({
      query: variables => ({ document: PostsBySearchWithHashtagAtPageDocument, variables })
    }),
    PostsBySearchWithHashtagAtToken: build.query<
      PostsBySearchWithHashtagAtTokenQuery,
      PostsBySearchWithHashtagAtTokenQueryVariables | void
    >({
      query: variables => ({ document: PostsBySearchWithHashtagAtTokenDocument, variables })
    }),
    createPost: build.mutation<CreatePostMutation, CreatePostMutationVariables>({
      query: variables => ({ document: CreatePostDocument, variables })
    }),
    updatePost: build.mutation<UpdatePostMutation, UpdatePostMutationVariables>({
      query: variables => ({ document: UpdatePostDocument, variables })
    }),
    repost: build.mutation<RepostMutation, RepostMutationVariables>({
      query: variables => ({ document: RepostDocument, variables })
    }),
    removePost: build.mutation<RemovePostMutation, RemovePostMutationVariables>({
      query: variables => ({ document: RemovePostDocument, variables })
    })
  })
});

export { injectedRtkApi as api };
